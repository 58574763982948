import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import Countdown from 'react-countdown'
import { getQueryEventTracker } from '@helpers/helper.actions'
import { informCustomEvent } from '@helpers/helper.analytics'
import { ModuleActionProps } from 'types'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'

const TopAlert = ({
  linkTo,
  linkTxt,
  textAlert,
  showOn,
  eventDate,
  utmValue,
  name,
  property,
  development,
  page,
  action,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replace('/', '')

  const [loaded, setIsLoad] = useState(false)
  const [closed, setClosed] = useState(false)
  const [sendTrackerEvent] = useSendTrackerEvent()

  useEffect(() => {
    // setIsLoad(false)
    // setClosed(false)
    if (!closed) {
      if (checkPage()) {
        if (
          (action.section === 'Detalle de Emprendimiento' && development?.id) ||
          (action.section === 'Detalle de propiedad' && property?.id) ||
          (action.section !== 'Detalle de Emprendimiento' &&
            action.section !== 'Detalle de propiedad')
        ) {
          if (closed === false) {
            console.log(page.id)
            console.log(action)
            informCustomEvent(
              'SHOW_' +
                page.id +
                '_' +
                (property && action.section === 'Detalle de propiedad'
                  ? property.id.toString()
                  : development && action.section === 'Detalle de Emprendimiento'
                  ? development.id.toString()
                  : '0') +
                '_' +
                action.id,
            )
            sendTrackerEvent(
              getQueryEventTracker({
                page: page,
                action: action,
                type: 'SHOW',
                data_id: property
                  ? property.id.toString()
                  : development
                  ? development.id.toString()
                  : '0',
                url: location.href,
                extra_title:
                  action.section === 'Detalle de propiedad' && property
                    ? property?.address
                    : development && action.section === 'Detalle de Emprendimiento'
                    ? development.name
                    : '',
              }),
            )
            document.getElementById('top-alert-' + name)?.classList.add('animate-show')
            document.getElementById('dynamic_header')?.classList.toggle('top-custom')
            document.getElementById('header')?.classList.toggle('top-custom')
            setTimeout(() => {
              setIsLoad(true)
            }, 1000)
          }
        }
      } else {
        setClosed(true)
        document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
        document.getElementById('header')?.classList.remove('top-custom')
        document.getElementById('dynamic_header')?.classList.remove('top-custom')
      }
    }
  }, [pathname, showOn, property, development])

  useEffect(() => {
    if (loaded) {
      document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
      document.getElementById('header')?.classList.remove('top-custom')
      document.getElementById('dynamic_header')?.classList.remove('top-custom')
    }
  }, [pathname])

  useEffect(() => {
    if (closed) {
      document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
      document.getElementById('header')?.classList.remove('top-custom')
      document.getElementById('dynamic_header')?.classList.remove('top-custom')
    }
  }, [closed])

  // Check where shows

  // Render countdown
  const rendererCtd = ({ days, hours, minutes, seconds }: any) => (
    <div className="counter d-flex">
      <span className="mx-2">
        <span className="date-val">{days}</span> DÍAS
      </span>
      <span className="me-2">
        <span className="date-val">{hours}</span> HS
      </span>
      <span className="me-2">
        <span className="date-val">{minutes}</span> MIN
      </span>
      <span className="me-2">
        <span className="date-val">{seconds}</span> SEG
      </span>
    </div>
  )

  const capitalizeFirst = (text: string) => text[0].toUpperCase() + text.slice(1, text.length)

  const checkPage = () => {
    return true
  }

  if (!checkPage) {
    document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
    document.getElementById('header')?.classList.remove('top-custom')
    document.getElementById('dynamic_header')?.classList.remove('top-custom')
  }

  const removeStyles = (): void => {
    console.log('remuevo')
    document.getElementById('top-alert-' + name)?.classList.remove('animate-show')
    document.getElementById('header')?.classList.remove('top-custom')
    document.getElementById('dynamic_header')?.classList.remove('top-custom')
  }

  return checkPage() ? (
    <div
      id={'top-alert-' + name}
      className={
        // 'alert alert-styles alert-dismissible fade show ' +
        'custom-alert fade show ' + (showOn.toLowerCase() === 'inicio' ? 'isHome' : '')
      }
    >
      <div className="container-fluid">
        {/* Counter */}
        {eventDate && (
          <div className="counter-wrapper">
            Quedan 
            <Countdown
              date={new Date(eventDate).getTime()}
              renderer={rendererCtd}
            />
          </div>
        )}
        {/* Text */}
        {textAlert && (
          <div className={`text-content my-2`}>
            {eventDate ? ` ${textAlert}` : capitalizeFirst(textAlert)}
          </div>
        )}
        {/* Link */}
        {linkTo && linkTxt && (
          <a
            href={linkTo + utmValue}
            onClick={() => {
              informCustomEvent(
                'PUSH_' +
                  page.id +
                  '_' +
                  (property && action.section === 'Detalle de propiedad'
                    ? property.id.toString()
                    : development && action.section === 'Detalle de Emprendimiento'
                    ? development.id.toString()
                    : '0') +
                  '_' +
                  action.id,
              )
              sendTrackerEvent(
                getQueryEventTracker({
                  page: page,
                  action: action,
                  type: 'PUSH',
                  data_id:
                    property && action.section === 'Detalle de propiedad'
                      ? property.id.toString()
                      : development && action.section === 'Detalle de Emprendimiento'
                      ? development.id.toString()
                      : '0',
                  url: location.href,
                  extra_title:
                    action.section === 'Detalle de propiedad' && property
                      ? property?.address
                      : development && action.section === 'Detalle de Emprendimiento'
                      ? development.name
                      : '',
                }),
              )
            }}
            className="btn-link"
            target="_blank"
          >
            {linkTxt}
          </a>
        )}
      </div>
      {/* X close button */}
      <i
        className="icon-plus"
        id="closeAlert"
        // data-bs-dismiss="alert"
        onClick={() => {
          //   document.getElementById('gatsby-focus-wrapper')?.classList.remove('top-custom-body')
          setClosed(true)
        }}
        // aria-label="Close"
      ></i>
    </div>
  ) : (
    <div></div>
  )
}

export default TopAlert
