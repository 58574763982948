import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { informCustomEvent } from '@helpers/helper.analytics'
import { getCodes, getQueryEventTracker } from '@helpers/helper.actions'
import { ModuleActionProps } from 'types'
import { useCreateApplicationMutation as useSendTrackerEvent } from '@apis/mediacore/tracker'
import { ElementHTML } from '@helpers/helper.rendering'

const SquarePopup = ({
  align,
  flat,
  linkTo,
  showOn,
  image,
  utmValue,
  property,
  development,
  action,
  page,
  text_popup,
}: ModuleActionProps) => {
  const location = useLocation()
  const pathname = location.pathname.replaceAll('/', '')
  const [sendTrackerEvent] = useSendTrackerEvent()

  const [isOpen, setIsOpen] = useState(false)

  // Timeout to show the component
  useEffect(() => {
    if (checkPage() && !isOpen) {
      if (
        (action.section === 'Detalle de Emprendimiento' && development) ||
        (action.section === 'Detalle de propiedad' && property) ||
        (action.section !== 'Detalle de Emprendimiento' &&
          action.section !== 'Detalle de propiedad')
      ) {
        informCustomEvent(
          'SHOW_' +
            page.id +
            '_' +
            (property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.id.toString()
              : '0') +
            '_' +
            action.id,
        )
        sendTrackerEvent(
          getQueryEventTracker({
            page: page,
            action: action,
            type: 'SHOW',
            data_id:
              property && action.section === 'Detalle de propiedad'
                ? property.id.toString()
                : development && action.section === 'Detalle de Emprendimiento'
                ? development.id.toString()
                : '0',
            url: location.href,
            extra_title:
              action.section === 'Detalle de propiedad' && property
                ? property?.address
                : development && action.section === 'Detalle de Emprendimiento'
                ? development.name
                : '',
          }),
        )
        setTimeout(() => {
          if ((flat && screen.width > 992) || !flat)
            document.getElementById('squarePopUpBtn')?.click()
          setIsOpen(true)
        }, 50)
      }
    }
  }, [pathname, property, development, location])

  // Check align
  const checkAlign = () => {
    return align?.toLowerCase() === 'left'
      ? 'align-left'
      : align?.toLowerCase() === 'right'
      ? 'align-right'
      : align?.toLowerCase() === 'center'
      ? 'align-center'
      : ' is_square '
  }

  // Limit the height
  const checkFlat = () => {
    return flat ? 'flat-width d-none d-lg-flex' : ''
  }

  // Go to link, disable popup
  const goTo = (linkButton = '') => {
    if (linkButton) {
      window.open(linkButton + utmValue, '_blank', 'noopener,noreferrer')
      informCustomEvent(
        'PUSH_' +
          page.id +
          '_' +
          (property && action.section === 'Detalle de propiedad'
            ? property.id.toString()
            : development && action.section === 'Detalle de Emprendimiento'
            ? development.id.toString()
            : '0') +
          '_' +
          action.id,
      )
      sendTrackerEvent(
        getQueryEventTracker({
          page: page,
          action: action,
          type: 'PUSH',
          data_id:
            property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.id.toString()
              : '0',
          url: location.href,
          extra_title:
            action.section === 'Detalle de propiedad' && property
              ? property?.address
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.name
              : '',
        }),
      )
      return true
    }
    if (linkTo) {
      document.getElementById('closePopup')?.click()
      window.open(linkTo + utmValue, '_blank', 'noopener,noreferrer')
      informCustomEvent(
        'PUSH_' +
          page.id +
          '_' +
          (property && action.section === 'Detalle de propiedad'
            ? property.id.toString()
            : development && action.section === 'Detalle de Emprendimiento'
            ? development.id.toString()
            : '0') +
          '_' +
          action.id,
      )
      sendTrackerEvent(
        getQueryEventTracker({
          page: page,
          action: action,
          type: 'PUSH',
          data_id:
            property && action.section === 'Detalle de propiedad'
              ? property.id.toString()
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.id.toString()
              : '0',
          url: location.href,
          extra_title:
            action.section === 'Detalle de propiedad' && property
              ? property?.address
              : development && action.section === 'Detalle de Emprendimiento'
              ? development.name
              : '',
        }),
      )
    }
  }

  // Check where shows
  const checkPage = () => {
    return true
  }

  return (
    <>
      {checkPage() ? (
        <div className="article">
          <button
            type="button"
            id="squarePopUpBtn"
            data-bs-toggle="modal"
            data-bs-target="#squarePopUp"
          ></button>
          <div
            className={'modal fade ' + (text_popup?.title?.size ? 'is-content' : '')}
            id="squarePopUp"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex={-1}
          >
            <div className={`modal-dialog ${checkAlign()} ${checkFlat()}`}>
              <div
                className={`modal-content ${checkFlat()}`}
                style={{
                  backgroundImage:
                    'url(' + (text_popup?.background_type == 'image' ? image : '') + ')',
                  backgroundColor:
                    text_popup?.background_type == 'image'
                      ? 'transparent'
                      : text_popup?.background_color,
                }}
              >
                <div
                  className="modal-header"
                  onClick={() => document.getElementById('closePopup')?.click()}
                >
                  <i
                    className="icon-plus"
                    role="button"
                    id="closePopup"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => document.getElementById('closePopup')?.click()}
                  ></i>
                </div>
                <div
                  className={`modal-body ${checkFlat()} ${
                    text_popup?.title
                      ? 'd-flex flex-column align-items-center text-center justify-content-center'
                      : ''
                  }`}
                  onClick={() => goTo()}
                >
                  {text_popup?.title?.size && (
                    <ElementHTML
                      children={text_popup?.title?.text || ''}
                      style={{ color: text_popup?.title?.color }}
                      elementType={text_popup?.title?.size || 'h2'}
                      className={'element-range'}
                    />
                  )}
                  {text_popup?.paragraph?.size && (
                    <ElementHTML
                      children={text_popup?.paragraph?.text || ''}
                      style={{ color: text_popup?.paragraph?.color }}
                      elementType={text_popup?.paragraph?.size || 'h2'}
                      className={'mt-2 element-range'}
                    />
                  )}
                  {text_popup?.button?.link && (
                    <div
                      className="btn mt-lg-4 mt-3"
                      style={{
                        color: text_popup?.button?.color,
                        background: text_popup?.button?.background_color,
                      }}
                      onClick={() => goTo(text_popup?.button?.link)}
                    >
                      {text_popup?.button?.text}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SquarePopup
