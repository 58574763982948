// import { getOperations } from "./helper.properties"
// import ROUTES from "../../routes.js"

import {
  Development,
  FiltersPropertiesTokkoQuery,
  Novelty,
  Photo,
  PriceTokko,
  Property,
  RealEstateDataPage,
  RealEstateDataPageComponent,
  RealEstateDataPageComponentData,
  Video,
} from 'types'
import { getOperations } from './helper.properties'

export const getCover = (photos: Photo[]) => {
  let parsePhotos = photos?.map((photo) => ({
    ...photo,
    image:
      photo.image.slice(-3) == '.jp'
        ? photo.image + 'g'
        : photo.image.slice(-2) == '.j'
        ? photo.image + 'pg'
        : photo.image,
  }))
  if (parsePhotos?.length > 0) {
    if (parsePhotos.find((photo: Photo) => photo.is_front_cover)) {
      return parsePhotos.find((photo: Photo) => photo.is_front_cover)!
    } else {
      return parsePhotos[0]
    }
  }
  // const response:Photo
  return {
    original: 'https://mediahaus.com.ar/noimage.jpg',
    image: 'https://mediahaus.com.ar/noimage.jpg',
  }
}

export const getPreviewSlider = (photos: Photo[]) => {
  let array: any
  if (photos) {
    array = photos.slice(0, 3)
    array[0] = getCover(photos)
  }
  return array
}

export const getPhotos = (photos: Photo[]) => {
  if (photos?.length > 0) {
    let parsePhotos = photos.map((photo) => ({
      ...photo,
      image: photo.image.slice(-3) == '.jp' ? photo.image + 'g' : photo.image,
    }))
    return parsePhotos?.filter((photo: Photo) => !photo.is_blueprint)
  }
  return [
    {
      original: 'https://mediahaus.com.ar/noimage.jpg',
      image: 'https://mediahaus.com.ar/noimage.jpg',
      is_front_cover: false,
    },
  ]
}

export const getVideos = (videos: Video[]) => {
  if (videos) {
    const list_videos = videos?.filter(
      (video) =>
        video.provider?.toLowerCase() === 'youtube' || video.provider?.toLowerCase() === 'vimeo',
    )
    return list_videos
  }
  return []
}

export const getDescription = (description: string, characters: number) => {
  if (description) {
    if (description.length === 0 || description === undefined) return ['Sin descripción']
    const description_response = description
      ?.replaceAll('&nbsp;', '')
      .slice(0, characters)
      .split('\n')
    return description_response
  }
  return ['Sin descripción']
}

export const getTextShort = (text: string, length: number) => {
  return text?.length > length ? text?.substr(0, length) + '...' : text
}

export const descriptionIsNull = (description: string) => {
  if (description) {
    if (description.length === 0) return true
  }
  return false
}

export const getBrText = (text: string) => {
  const description = text.split('\n')
  return description
}

export const existDevelopment = (developments: Development[]) => {
  //any:development
  if (developments?.length > 0) {
    return true
  }
  return false
}

export const existOperation = (operation: string, filters: any) => {
  switch (operation) {
    case 'venta':
      if (
        filters?.objects?.operation_types.find(
          (operation: { operation_type: number }) => operation.operation_type === 1,
        )
      )
        return true
      break
    case 'alquiler':
      if (
        filters?.objects?.operation_types.find(
          (operation: { operation_type: number }) => operation.operation_type === 2,
        )
      )
        return true
      break
    case 'alquiler-temporario':
      if (
        filters?.objects?.operation_types.find(
          (operation: { operation_type: number }) => operation.operation_type === 3,
        )
      )
        return true
      break
  }
  return false
}

// export const getWhatsapp = (phone = "", text = "") => {
//   return (
//     "https://" +
//     (window.screen.width < 992 ? "api" : "web") +
//     ".whatsapp.com/send?" +
//     (phone !== "" ? "&phone=" + phone : "") +
//     (text !== "" ? "&text=" + text : "")
//   )
// }

// export const getMessageWhatsapp = (development:Development,property:Property,url:string) => {
export const getMessageWhatsapp = (development: Development, property: Property, url: string) => {
  // console.log(property)
  if (development?.name && url.includes('emprendimiento/')) {
    return `Quería consultar sobre el emprendimiento ${development?.name}. %0A ${url.replaceAll(
      ' ',
      '-',
    )}`
  }
  if (property?.id && url.includes('propiedad/')) {
    return `Hola! quería consultar sobre la propiedad en ${
      getOperations(property)[0]
    } ubicada en ${property?.fake_address.replaceAll(' ', '-')}. %0A ${url.replaceAll(' ', '-')}`
  }
  return '¡Hola!'
}

// export const existArray = array => {
//   if (array.find(array => array === "")) {
//     return false
//   }
//   return true
// }

// export const getPhonesText = phones => {
//   let text = ""
//   if (phones) {
//     phones.forEach((phone, index) => {
//       text += phone + (index === phones.length - 1 ? "" : " / ")
//     })
//   }
//   return text
// }

export const getQuality = (photo: Partial<Photo>) => {
  return photo?.image
}

// export const getSeoDescription = url => {}

export const getPriceForMarker = (price: PriceTokko[]) => {
  if (price === undefined || price.length === 0) return ''
  const value = price[0]?.price
  let response = price[0]?.currency === 'USD' ? `USD ` : `ARS `
  if (value?.toString().toLowerCase() === 'precio a consultar') {
    return 'CONSULTAR'
  }
  //transform 100.000 to 100k and 1.000.000 to 1m
  if (typeof value === 'number') {
    if (value >= 1_000_000) {
      if (Number.isInteger(value / 1_000_000)) {
        response += `${value / 1_000_000}M`
      } else {
        response += `${(value / 1_000_000).toFixed(2)}M`
      }
    } else if (value >= 1000) {
      if (Number.isInteger(value / 1000)) {
        response += `${(value / 1000).toFixed(0)}K`
      } else {
        response += `${(value / 1000).toFixed(2)}K`
      }
    } else {
      response += `${value}`
    }
    return response
  }
}

// export const getPriceForMarker = price => {
//   if (price === undefined || price.length === 0) return ""
//   const value = price[0]?.price
//   let response = price[0]?.currency === "USD" ? `USD ` : `ARS `
//   if (value?.toString().toLowerCase() === "precio a consultar") {
//     return "CONSULTAR"
//   }
//   //transform 100.000 to 100k and 1.000.000 to 1m
//   if (value > 0) {
//     if (value >= 1000000) {
//       if (Number.isInteger(value / 1000000)) {
//         response += `${value / 1000000}M`
//       } else {
//         response += `${(value / 1000000).toFixed(2)}M`
//       }
//     } else if (value >= 1000) {
//       if (Number.isInteger(value / 1000)) {
//         response += `${(value / 1000).toFixed(0)}K`
//       } else {
//         response += `${(value / 1000).toFixed(2)}K`
//       }
//     } else {
//       response += `${value}`
//     }
//   }
//   return response
// }

export const makeItemsNav = (
  filters: any,
  novelties: any,
  pages: RealEstateDataPage[],
  jobs: any,
) => {
  if (novelties?.news?.length < 1 || novelties?.news?.length === undefined) {
    pages = pages.filter((page) => !page.module?.includes('BLOG'))
  }

  if (jobs?.length < 1 || jobs?.length === undefined) {
    pages = pages.filter((page) => !page.module?.includes('JOBS'))
  }

  if (
    pages.find((page) => page.path.includes('venta'))?.hidden ||
    !filters?.objects?.operation_types.find(
      (operation: { operation_type: number }) => operation.operation_type === 1,
    )
  ) {
    pages = pages.filter((page) => !page.path.includes('venta'))
  }
  if (
    pages.find((page) => page.path.includes('alquiler'))?.hidden ||
    !filters?.objects?.operation_types.find(
      (operation: { operation_type: number }) => operation.operation_type === 2,
    )
  ) {
    pages = pages.filter((page) => !page.path.includes('alquiler'))
  }
  if (
    pages.find((page) => page.path.includes('temporario'))?.hidden ||
    !filters?.objects?.operation_types.find(
      (operation: { operation_type: number }) => operation.operation_type === 3,
    )
  ) {
    pages = pages.filter((page) => !page.path.includes('temporario'))
  }
  //   items.splice(1, 1)

  return pages.filter((page) => !page.hidden_nav)
}

export const getFile = (file: any) => {
  let res = { name: '', ext: '', url: '' }
  res.name = file.file.split('/').at(-1).split('.')[0]
  res.ext = file.file.split('/').at(-1).split('.')[1]
  res.url = file.file

  return res
}

// export const validarAPIKey = apiKey => {
//   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=validar&key=${apiKey}`

//    fetch(url)
//     .then(response => response.json())
//     .then(data => {
//       if (data.status === "OK") {
//         return true
//       } else {
//         // La API Key es inválida o no está habilitada
//         return false
//       }
//     })
//     .catch(error => {
//       // Ocurrió un error al hacer la solicitud
//       console.error("Error al validar la API Key:", error)
//       return false
//     })
// }

export const makeShareUrl = ({
  novelty,
  development,
  property,
  href = '',
  isMediacore = false,
}: {
  property?: Property
  env?: string
  CLIENTID?: number
  novelty?: Novelty
  development?: Development
  href?: string
  isMediacore?: boolean
}) => {
  return href

  // const domain = 'https://share.mediacore.app/'

  // const operation = property?.id
  //   ? href.split('/propiedad/')[1].split('/')[0].charAt(0).toUpperCase() +
  //     href.split('/propiedad/')[1].split('/')[0].slice(1)
  //   : ''

  // const isStaging = process.env.GATSBY_APISERVER!.includes('staging')

  // const formateMessage = (str: string) => {
  //   return encodeURIComponent(str)
  //     .replace(/[!'()]/g, escape)
  //     .replace(/\*/g, '%2A')
  // }

  // if (novelty?.id) {
  //   return `${domain}${process.env.GATSBY_CLIENTID}/novedades/${novelty.id}?url=${formateMessage(href)}${
  //     isStaging ? '&env=staging' : ' '
  //   }`
  // }
  // if (development?.id) {
  //   return `${domain}${process.env.GATSBY_CLIENTID}/emprendimientos${isMediacore ? '_mediasite' : ''}/${
  //     development.id
  //   }?url=${formateMessage(href)}${isStaging ? '&env=staging' : ' '}`
  // }
  // if (property?.id) {
  //   return `${domain}${process.env.GATSBY_CLIENTID}/propiedades${isMediacore ? '_mediasite' : ''}/${
  //     property.id
  //   }?url=${formateMessage(href)}&operation=${formateMessage(operation)}${
  //     isStaging ? '&env=staging' : ' '
  //   }`
  // }
  // return ''
}

export const scrollToElement = (querySelector: any) => {
  document.querySelector(querySelector).scrollIntoView({ behavior: 'smooth' })
}

// Función para normalizar caracteres especiales
export function normalizeURL(url: string) {
  const urli = decodeURIComponent(url)
  const mapaCaracteresEspeciales: any = {
    '%C3%81': 'Á',
    '%C3%89': 'É',
    '%C3%8D': 'Í',
    '%C3%93': 'Ó',
    '%C3%9A': 'Ú',
    '%C3%91': 'Ñ',
    '%C3%A1': 'á',
    '%C3%A9': 'é',
    '%C3%AD': 'í',
    '%C3%B3': 'ó',
    '%C3%BA': 'ú',
    '%C3%B1': 'ñ',
    // Puedes añadir más caracteres según tus necesidades
  }

  return urli.replace(/%[0-9A-F]{2}/g, (match) => mapaCaracteresEspeciales[match] || match)
}

import React, { CSSProperties } from 'react'

export const ElementHTML = ({
  children,
  elementType,
  className,
  style,
  onClick,
}: {
  children: any
  elementType: string
  style?: CSSProperties
  className?: string
  onClick?: () => void
}) => {
  // // Crear el elemento HTML del tipo especificado
  // let element = document.createElement(elementType)

  // // Establecer el contenido de texto del elemento
  // element.textchildren = children
  // if (className) {
  //   element.className = className
  // }
  // // Devolver el elemento HTML creado
  // return element
  return React.createElement(
    elementType,
    { style: style, className: className, onClick: onClick },
    children,
  )
  // Devolver el elemento con el contenido y las clases aplicadas
}

import { ElementType } from 'react'

export const HTMLContentElement = ({
  elementType,
  className,
  htmlContent,
  style,
}: {
  elementType: string
  className?: string
  htmlContent?: string
  style?: CSSProperties
}) => {
  return React.createElement(elementType, {
    className,
    style,
    dangerouslySetInnerHTML: { __html: htmlContent },
  })
}

export const getBackgroundColor = (paragraphColor: string, paragraphHexa: string) => {
  const colors = {
    'text-primary': 'var(--primaryColor)',
    'text-secondary': 'var(--secondaryColor)',
    'text-tertiary': 'var(--tertiaryColor)',
    'text-picker': paragraphHexa ?? '#000',
  }

  return colors[paragraphColor as keyof typeof colors] || paragraphColor
}
