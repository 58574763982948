import { Development, Property } from 'types'

export const makeJsonContact = ({
  name,
  cellphone,
  email,
  text,
  tags,
  property,
  development,
}: {
  name?: string
  cellphone?: string
  email?: string
  text?: string
  tags?: string[]
  property?: Property
  development?: Development
}) => {
  const body = JSON.stringify({
    name: name,
    cellphone: cellphone,
    email: email,
    text: `- Nueva Consulta - \n 
    Nombre: ${name || 'Sin definir'} \n 
    Teléfono: ${cellphone || 'Sin definir'} \n 
    Email: ${email} \n 
    Mensaje: ${text || 'Sin definir'}`,
    tags,
    properties: property?.id,
    developments: development?.id,
  })
  return body
}
export const makeJsonServices = ({
  name,
  email,
  lastname,
  phone,
  operation,
  type,
  location,
  sup_cub,
  sup_tot,
  rooms,
  garage,
  age,
  amenities,
  text,
  tags,
  company,
}: {
  name?: string
  lastname?: string
  email?: string
  phone?: string
  operation?: string
  type?: string
  location?: string
  sup_cub?: string
  sup_tot?: string
  rooms?: string
  garage?: string
  age?: string
  amenities?: string[]
  text?: string
  tags?: string[]
  company?: string
}) => {
  const body = JSON.stringify({
    name: name,
    email: email,
    text: getMessageServices({
      name,
      email,
      lastname,
      phone,
      operation,
      type,
      location,
      sup_cub,
      sup_tot,
      rooms,
      garage,
      age,
      company,
      amenities,
    }),
    tags,
  })
  return body
}

export const getMessageServices = ({
  name,
  email,
  lastname,
  phone,
  operation,
  type,
  location,
  sup_cub,
  sup_tot,
  rooms,
  garage,
  age,
  company,
  amenities,
}: {
  name?: string
  lastname?: string
  email?: string
  phone?: string
  operation?: string
  type?: string
  location?: string
  sup_cub?: string
  sup_tot?: string
  rooms?: string
  garage?: string
  age?: string
  amenities?: string[]
  company?: string
}) => {
  const message = `- Nueva Tasación - \n 
  Nombre: ${name + (lastname ? ' ' + lastname : '') || 'Sin definir'} \n 
  Email: ${email} \n 
  Telefono: ${phone || 'Sin definir'} \n 
  Operación: ${operation || 'Sin definir'} \n 
  Tipo de propiedad: ${type || 'Sin definir'} \n 
  Ubicación: ${location || 'Sin definir'} \n 
  Superficie cubierta: ${sup_cub || 'Sin definir'} \n 
  Superficie total: ${sup_tot || 'Sin definir'} \n 
  Ambientes: ${rooms || 'Sin definir'} \n 
  Garages: ${garage || 'Sin definir'} \n 
  Antigüedad: ${age || 'Sin definir'} \n 
  Amenities: ${amenities?.join(',') || 'Sin definir'}
  ${company ? `Empresa: ${company}` : ''}`

  return message
}
