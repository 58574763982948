import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { makeItemsNav } from '@helpers/helper.rendering'
import useQuerySummary from '@hooks/useQuerySummary'
import { Link, navigate } from 'gatsby'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { RealEstateDataHeaderData, RealEstateDataPage, StatePropertiesSlice } from 'types'
import { useLocation } from '@reach/router'
import { getfavorites } from '@helpers/helper.favorites'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'

interface ExternalProps {
  pages: RealEstateDataPage[]
  development_id: string
  property_id: string
  novelty_id: string
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header4 = (props: CombinedProps) => {
  const { global_data, pages, buttonCompare, height, logoSize, fontColor } = props

  const { updaterFavorites } = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )!

  const { id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko

  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })

  const logo = global_data.logo
  const logoMobile = global_data.logoMobile

  console.log(height)

  const [isMounted, setIsMounted] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen)
  }

  const location = useLocation()

  useEffect(() => {
    setIsMounted(true)
    document.documentElement.style.setProperty(
      '--navbar-height',
      height?.desktop
        ? screen.width < 992
          ? height.mobile + (height.scalePixels ? 'px' : 'vh')
          : height.desktop + (height.scalePixels ? 'px' : 'vh')
        : '170px',
    )
  }, [])

  useEffect(() => {
    if (isMounted) {
      setListFavorites(getfavorites('prop', 'template-site'))
    }
  }, [updaterFavorites])

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const countFavorites = listFavorites.length

  return isMounted ? (
    <header
      id="header"
      className="header c"
    >
      <Navbar className="navbar-sticky py-0">
        <Container
          fluid
          className="mx-lg-3"
          style={{ maxWidth: '100%', height: '100%' }}
        >
          <Row className="w-100 d-xl-flex d-none">
            <Col
              lg={4}
              xs={6}
              className="justify-content-lg-start justify-content-center px-xl-5"
            >
              <Link
                aria-label="Enlace inicio"
                className="d-flex align-items-center m-0"
                to="/"
              >
                <img
                  alt="logo-header"
                  src={logo}
                  height={
                    logoSize && height?.desktop
                      ? screen.width < 992
                        ? (parseInt(height?.mobile) * parseInt(logoSize)) / 100 + 'px'
                        : (parseInt(height?.desktop) * parseInt(logoSize)) / 100 + 'px'
                      : '80px'
                  }
                  width={'auto'}
                  className="d-lg-inline-block d-none align-top logo-header"
                />
              </Link>
            </Col>
            <Col
              lg={8}
              xs={10}
              className="d-flex links-column"
              style={{ alignSelf: 'center', justifyContent: 'flex-end' }}
            >
              <Nav className="nav-menu d-flex justify-content-between w-100">
                {makeItemsNav(allSummaryData, allBlogData, pages, undefined)
                  .filter(
                    (page) => !page.path.includes('detalle') && !page.path.includes('favoritos'),
                  )
                  .filter((page) => page.path !== '/')
                  .map(({ path, title }, index) => (
                    <Fragment key={index}>
                      <Nav.Item className="me-3">
                        {path.includes('venta') ||
                        path.includes('alquiler') ||
                        (path.includes('temporario') && !location.pathname.includes(path)) ? (
                          <a
                            style={{ color: fontColor || '#fff' }}
                            className={
                              (location.pathname.includes(path) &&
                                location.pathname !== '' &&
                                path !== '/') ||
                              (path === '/' && location.pathname === '/')
                                ? 'custom-link active text-nowrap'
                                : 'custom-link text-nowrap'
                            }
                            href={path}
                          >
                            {title}
                          </a>
                        ) : (
                          <Link
                            onClick={() => {
                              scrollTo(0, 0)
                            }}
                            style={{ color: fontColor || '#fff' }}
                            className={
                              (location.pathname.includes(path) &&
                                location.pathname !== '' &&
                                path !== '/') ||
                              (path === '/' && location.pathname === '/')
                                ? 'custom-link active text-nowrap'
                                : 'custom-link text-nowrap'
                            }
                            to={path}
                          >
                            {title}
                          </Link>
                        )}
                      </Nav.Item>
                    </Fragment>
                  ))}
                <Nav.Item className="me-5 d-flex align-items-center d-none d-lg-block">
                  <div
                    className={'favourites-button'}
                    onClick={() => {
                      if (countFavorites.length === 1) {
                        toast.error(
                          'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                        )
                      } else {
                        navigate(buttonCompare?.link ?? '/')
                      }
                    }}
                  >
                    <span className="favourites-text p-0">
                      <Toaster />
                      {countFavorites}
                    </span>
                    <i
                      className={`icon-favoritos-heart  ms-3`}
                      style={{ fontSize: '1.2rem' }}
                    />
                  </div>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="w-100 h-100 d-flex justify-content-center align-items-center d-xl-none d-block pt-2">
            <Col
              xs={2}
              className="d-flex justify-content-center align-items-center"
            >
              <div
                className={'favourites-button'}
                onClick={() => {
                  const favorites = getfavorites('prop', 'template-site')
                  if (favorites.length === 1) {
                    toast.error(
                      'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                    )
                  } else {
                    navigate(buttonCompare?.link ?? '/')
                  }
                }}
              >
                <span className="favourites-text p-0">
                  <Toaster />
                  {getfavorites('prop', 'template-site').length}
                </span>
                <i
                  className={`icon-favoritos-heart ms-1`}
                  style={{ fontSize: '1.2rem' }}
                />
              </div>
            </Col>
            <Col
              xs={8}
              className="px-0 h-100 d-flex justify-content-center align-items-center"
            >
              <Link
                aria-label="Enlace inicio"
                className="d-xl-block d-flex align-items-center m-0"
                to="/"
              >
                <img
                  alt="logo-header-mobile"
                  src={logoMobile}
                  style={{
                    maxHeight: '60px',
                    maxWidth: '100%',
                  }}
                  className="d-xl-none d-block align-top"
                />
              </Link>
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-center align-items-center"
            >
              <button
                aria-label="toggle"
                className="toggle-nav d-xl-none"
                onClick={handleMenuToggle}
              >
                <div className="d-flex align-items-center">
                  <span>
                    {menuOpen ? (
                      <span className="icon-close-light close-icon" />
                    ) : (
                      <>
                        {' '}
                        <i></i>
                        <i></i>
                        <i></i>
                      </>
                    )}
                  </span>
                </div>
              </button>
            </Col>
          </Row>
        </Container>
      </Navbar>
      {menuOpen && (
        <div className="mobile-menu">
          <Nav className="d-flex flex-column">
            {makeItemsNav(allSummaryData, allBlogData, pages, undefined)
              .filter((page) => !page.path.includes('detalle') && !page.path.includes('favoritos'))
              .map(({ path, title }, index) => (
                <Fragment key={index}>
                  <Nav.Item className="my-3 ms-5">
                    {path.includes('venta') ||
                    path.includes('alquiler') ||
                    (path.includes('temporario') && !location.pathname.includes(path)) ? (
                      <a
                        className={
                          (location.pathname.includes(path) &&
                            location.pathname !== '' &&
                            path !== '/') ||
                          (path === '/' && location.pathname === '/')
                            ? 'custom-link active'
                            : 'custom-link'
                        }
                        href={path}
                        onClick={() => setMenuOpen(false)}
                      >
                        {title}
                      </a>
                    ) : (
                      <Link
                        onClick={() => {
                          scrollTo(0, 0)
                          setMenuOpen(false)
                        }}
                        className={
                          (location.pathname.includes(path) &&
                            location.pathname !== '' &&
                            path !== '/') ||
                          (path === '/' && location.pathname === '/')
                            ? 'custom-link active'
                            : 'custom-link'
                        }
                        to={path}
                      >
                        {title}
                      </Link>
                    )}
                  </Nav.Item>
                </Fragment>
              ))}
          </Nav>
        </div>
      )}
    </header>
  ) : (
    ''
  )
}

export default Header4
