import { PriceTokko, Property, Development, PropertyMediacore } from 'types'
import { ROUTES } from '../../routes'

const {
  SALE_VIEW_DATA: SALE,
  RENT_VIEW_DATA: RENT,
  TEMPORARY_VIEW_DATA: TEMPORARY,
} = JSON.parse(process.env.extra_data || ROUTES)

export const getOperations = (property: Property) => {
  if (property?.operations) {
    const operationsArray: string[] = []
    property?.operations.map((operation) => {
      switch (operation.operation_type.toLowerCase()) {
        case 'temporario': {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case 'alquiler temporario': {
          operationsArray.push(TEMPORARY.name)
          break
        }
        case 'alquiler': {
          operationsArray.push(RENT.name)
          break
        }
        case 'venta': {
          operationsArray.push(SALE.name)
          break
        }
      }
    })
    return operationsArray
  }
  return ['']
}

export const getType = (property: Property) => {
  if (property?.type) {
    return property?.type.name
  }
  return ''
}

export const getEnvironment = (property: Property) => {
  if (property) {
    return property?.room_amount
  }
  return 0
}

export const getSurface = (property: Property) => {
  let surface = ''
  if (property?.type) {
    if (property?.type.name.toLowerCase() === 'terreno') {
      surface = property?.surface
    } else {
      surface = property?.total_surface
    }
    if (surface !== '')
      return (
        surface.split('.')[0] +
        ' ' +
        (property?.surface_measurement?.toLowerCase() == 'm2'
          ? 'm²'
          : property?.surface_measurement)
      )
  }
  return null
}

export const getRooms = (propert: Property) => {
  return propert?.suite_amount
}

export const getToilets = (propert: Property) => {
  return propert?.toilet_amount
}

export const getBathrooms = (propert: Property) => {
  return propert?.bathroom_amount
}

export const getSurfaces = (property: Property) => {
  const surfacesArray = {
    roofed: property?.roofed_surface.split('.')[0],
    unroofed: property?.unroofed_surface.split('.')[0],
    semiroofed_surface: property?.semiroofed_surface.split('.')[0],
    surface: property?.surface.split('.')[0],
    total_surface: property?.total_surface.split('.')[0],
    measurement: property?.surface_measurement.split('.')[0],
  }
  return surfacesArray
}

export const getLocation = (property: Property) => {
  if (property?.location) {
    return property?.location.name
  }
  return ''
}

export const getRegion = (property: Property) => {
  if (property?.location) {
    if (property?.location.short_location) {
      return property?.location.short_location.split(' | ')[1]
    }
    return property.location.city
  }
  return ''
}

export const getFakeAddres = (property: Property) => {
  return property?.fake_address
}

export const getRealAddress = (property: Property) => {
  if (property?.real_address.split('-').length > 2) {
    return property?.real_address.split('-')[0] + ' ' + property?.real_address.split('-')[1]
  }
  return property?.real_address
}

export const getPrices = (property: Property | PropertyMediacore, filter_operation = '') => {
  if (property?.operations) {
    const pricesArray: PriceTokko[] = []
    let operation_converted =
      filter_operation?.toLowerCase() === 'temporario'
        ? //@ts-ignore
          property?.client
          ? 'temporario'
          : 'alquiler temporario'
        : filter_operation
    property?.operations.map((operation) => {
      let price: any
      if (operation.operation_type.toLowerCase() === operation_converted?.toLowerCase()) {
        price = operation.prices[0]
        if (operation.prices[0]) {
          price = operation.prices[0]
        } else {
          price = operation.prices
        }
      } else {
        if (operation.prices[0]) {
          price = operation.prices[0]
        } else {
          price = operation.prices
        }
      }
      const obj: PriceTokko = {
        type: operation.operation_type,
        is_less_than_previous_price: price.is_less_than_previous_price,
        period: price.period,
        period_number: price.period_number,
        previous_price: price.previous_price,
        currency: !property?.web_price ? '' : price.currency,
        price: !property?.web_price ? 'Precio a consultar' : price.price,
      }
      pricesArray.push(obj)
    })
    if (filter_operation !== '') {
      return pricesArray.filter(
        (price) => price.type.toLowerCase() === operation_converted?.toLowerCase(),
      )
    }
    return pricesArray
  }
  return []
}

export const getTags = (property: Property, excludeTags: string[] = []) => {
  if (property?.tags) {
    return property.tags.filter((tag) => !excludeTags.includes(tag.name))
  }
  return []
}

export const getCustomTags = (property: Property) => {
  if (property?.custom_tags) {
    return property?.custom_tags
  }
  return []
}

export const hasGarage = (property: Property) => {
  return property?.parking_lot_amount ? true : false
}

export const countGarage = (property: Property) => {
  if (property?.parking_lot_amount) {
    if (property?.parking_lot_amount > 0) {
      return property?.parking_lot_amount
    }
    return 'No informado'
  } else {
    return 'No informado'
  }
}

export const makeLink = (property: Property, divisionPath?: string) => {
  return (
    (divisionPath ?? '') +
    '/propiedad/' +
    getOperations(property)[0].toLowerCase() +
    '/' +
    getFakeAddres(property)?.replaceAll(' ', '-')?.replaceAll('/', '-').toLowerCase() +
    // .normalize('NFD')
    // .replace(/[\u0300-\u036f]/g, '') +
    '/' +
    property?.id
  )
}

export const makeTextAmenities = (property: Property) => {
  let array = []
  let text = ''
  // Ambientes
  if (property?.room_amount > 0) {
    text += property?.room_amount ? property?.room_amount + ' ambiente' : ''
    text += property?.room_amount > 1 ? 's' : ''
    array.push(text)
  }
  // Dormitorios
  text = ''
  if (property?.suite_amount > 0) {
    text += ' ' + property?.suite_amount ? property?.suite_amount + ' dormitorio' : ''
    text += property?.suite_amount > 1 ? 's' : ''
    array.push(text)
  }

  return array
}

export const makeTextBathrooms = (property: Property) => {
  let array = []
  let text = ''
  // Baños
  if (property?.bathroom_amount > 0) {
    text += ' ' + property?.bathroom_amount ? property?.bathroom_amount + ' baño' : ''
    text += property?.bathroom_amount > 1 ? 's' : ''
    array.push(text)
  }
  text = ''
  if (property?.toilet_amount > 0) {
    text += ' ' + property?.toilet_amount ? property?.toilet_amount + ' toilette' : ''
    text += property?.toilet_amount > 1 ? 's' : ''
    array.push(text)
  }
  return array
}

export const makeTextSurfaces = (property: Property, short = false) => {
  let text = ''
  let array = []
  // Total - array index 0
  if (property?.total_surface !== '0' && property?.total_surface.toString() !== '0.00') {
    text +=
      ' ' + property?.total_surface !== '0' && property?.total_surface !== '0.00'
        ? property?.total_surface.split('.')[0] + ` m² ${short ? '' : 'tot. construído'}`
        : ''
    array.push(text)
    text = ''
  }
  // Roofed - array index 1
  if (
    property?.roofed_surface.toString() !== '0' &&
    property?.roofed_surface.toString() !== '0.00'
  ) {
    text +=
      property?.roofed_surface !== '0' && property?.roofed_surface.toString() !== '0.00'
        ? property?.roofed_surface.split('.')[0] + ` m² ${short ? '' : 'cubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Unroofed - array index 2
  if (property?.unroofed_surface !== '0' && property?.unroofed_surface !== '0.00') {
    text +=
      ' ' + property?.unroofed_surface !== '0' && property?.unroofed_surface !== '0.00'
        ? property?.unroofed_surface?.split('.')[0] + ` m² ${short ? '' : 'descubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Semiroofed - array index 3
  if (property?.semiroofed_surface !== '0' && property?.semiroofed_surface.toString() !== '0.00') {
    text +=
      ' ' + property?.semiroofed_surface !== '0'
        ? property?.semiroofed_surface.split('.')[0] + ` m² ${short ? '' : 'semicubiertos'}`
        : ''
    array.push(text)
    text = ''
  }
  // Terreno - array index 4
  if (property?.surface !== '0' && property?.surface !== '0.00') {
    text +=
      ' ' + property?.surface !== '0' && property?.surface !== '0.00'
        ? property?.surface.split('.')[0] + ` m² ${short ? '' : 'terreno'}`
        : ''
    array.push(text)
    text = ''
  }

  return array
}

export const makeTextTags = (property: Property) => {
  let text = ''
  let array = []
  if (property?.tags) {
    let index = 0
    while (index < 4 && index < property?.tags.length) {
      text += property?.tags[index].name + ''
      index++
      array.push(text)
      text = ''
    }
  }
  return array
}

export const makeTextParking = (property: Property) => {
  let text = ''
  if (property?.parking_lot_amount > 0) {
    text += ' ' + property?.parking_lot_amount ? property?.parking_lot_amount + ' cochera' : ''
    text += property?.parking_lot_amount > 1 ? 's' : ''
    if (property?.parking_lot_type) {
      if (!property?.parking_lot_type.includes('especificar')) {
        text += ' ' + property?.parking_lot_type?.toLowerCase()
        text += property?.parking_lot_amount > 1 ? 's' : ''
      }
    }
  }
  return text
}

export const getBluePrints = (property: Property | Development) => {
  if (property?.photos) {
    return property?.photos.filter((photo) => photo.is_blueprint === true)
  }
  return []
}

export const getTour = (property: Property | Development) => {
  if (property?.videos) {
    return property.videos.filter(
      (video) =>
        video.provider?.toLowerCase() === 'matterport' ||
        video.provider?.toLowerCase() === 'hauzd' ||
        video.provider?.toLowerCase() === '360magictour' ||
        video.provider?.toLowerCase() === 'vista360' ||
        video.provider?.toLowerCase() === 'roundme',
    )
  }
  return []
}

export const getTours = (property: Property | Development) => {
  if (property?.videos) {
    return property?.videos.filter(
      (video) =>
        video.provider?.toLowerCase() === 'matterport' ||
        video.provider?.toLowerCase() === 'hauzd' ||
        video.provider?.toLowerCase() === '360magictour' ||
        video.provider?.toLowerCase() === 'vista360' ||
        video.provider?.toLowerCase() === 'roundme',
    )
  }
  return []
}

export const getVideo = (property: Property | Development) => {
  if (property?.videos) {
    const list_videos = property?.videos.filter(
      (video) =>
        video.provider?.toLowerCase() === 'youtube' || video.provider?.toLowerCase() === 'vimeo',
    )
    return list_videos
  }
  return []
}

export const getLastVideo = (property: Property | Development) => {
  if (property?.videos) {
    const list_videos = property?.videos.filter(
      (video) =>
        video.provider?.toLowerCase() === 'youtube' || video.provider?.toLowerCase() === 'vimeo',
    )
    return list_videos[list_videos.length - 1]
  }
  return null
}

export const getAge = (property: Property, isMediacore = false) => {
  if (isMediacore) {
    return property?.age
  }
  if (property?.age === -1) {
    return 'En construcción'
  }
  if (property?.age === 0) {
    return 'A estrenar'
  }
  return property?.age ? `${property?.age} ${property?.age > 1 ? 'años' : 'año'}` : 'No informado'
}

// export const hasBarbacue = (property:Property) => {
//     // Return random boolean
//     if(property?.tags?.find(element => element.id === 35)){return true}
//     return false;
//     // 51, name: 'Pileta', type:
// }

// export const hasPool = (property:Property) => {
//     // Return random boolean
//     if(property){
//         if(property?.tags?.find(element => element.id === 51)){return true}
//     }
//         return false;
// }

export const hasTag = (property: Property, tag_id: number) => {
  // Return  boolean
  if (property) {
    if (property?.tags?.find((element) => element.id === tag_id)) {
      return true
    }
  }
  return false
}

export const isFeatured = () => {
  // Return random boolean
  return Math.random() < 0.1
}

export const getNameProducer = (property: Property) => {
  return property?.producer?.name
}

export const getPictureProducer = (property: Property) => {
  return property?.producer?.picture
}

export const getCellphoneProducer = (property: Property) => {
  return property?.producer?.cellphone
}

export const getEmailProducer = (property: Property) => {
  return property?.producer?.email
}

export const getOrientation = (property: Property) => {
  return property?.orientation
}

export const getShortOperation = (operation: string) => {
  console.log(operation)
  switch (operation.toLowerCase()) {
    case 'venta':
      return 'Venta'
    case 'alquiler':
      return 'Alquiler'
    default:
      return 'Temporario'
  }
}
export const searchUnitiesInResults = (
  properties?: { objects: Property[] },
  id?: number | undefined,
) => {
  let result = properties?.objects?.filter((property: Property) => property.development?.id === id)
  return result
}

export const hasBarbacue = (property: Property) => {
  // Return random boolean
  if (property?.tags?.find((element) => element.id === 35)) {
    return true
  }
  return false
  // 51, name: 'Pileta', type:
}
export const hasPool = (property: Property) => {
  // Return random boolean
  if (property) {
    if (property?.tags?.find((element) => element.id === 51)) {
      return true
    }
  }
  return false
}

export const excludeIncludeProperties = (
  properties: Property[] | PropertyMediacore[],
  includeTag: string[],
  excludeTag: string[],
) => {
  let props: any = properties
  let properties_filtered: any = includeTag.length === 0 ? properties : []
  if (includeTag) {
    for (const itag of includeTag) {
      let property_with_tag = props.filter((prop: any) =>
        prop.custom_tags.find(
          (tag: { name: string }) =>
            tag.name.normalize().toLowerCase() === itag.normalize().toLowerCase(),
        ),
      )
      props = props.filter(
        (prop: any) =>
          !prop.custom_tags.find(
            (tag: { name: string }) =>
              tag.name.normalize().toLowerCase() === itag.normalize().toLowerCase(),
          ),
      )
      properties_filtered = [...properties_filtered, ...property_with_tag]
    }
  }
  if (excludeTag) {
    for (const etag of excludeTag) {
      properties_filtered = properties_filtered.filter(
        (prop: Property) =>
          !prop.custom_tags.find((tag) => tag.name.toLowerCase() === etag.toLowerCase()),
      )
    }
  }

  return properties_filtered
}

export const removeDuplicateProperties = (properties: Property[]) => {
  let array: Property[] = []
  for (const prop of properties) {
    if (
      array.find(
        (element) => element.id === prop.id || prop.geo_lat === null || prop.geo_long === null,
      )
    ) {
      continue
    }
    array.push(prop)
  }

  console.log(array)
  return array
}
