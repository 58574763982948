import { useGetDevelopmentDetailQuery as useGetDevelopmentsTokkoQuery } from '@apis/middlewareTokkoApi/developments'
import { useGetDevelopmentMiddlewareQuery as useGetDevelopmentMiddlewareQuery } from '@apis/mediacore/owner'

import { useGetDevelopmentQuery as useGetDevelopmentsMediacoreQuery } from '@apis/mediacore/emprendimientos'

function useQueryDevelopment(id?: string, tokkoKey?: string) {
  const skip = !id

  const middlewarev2: boolean = process.env.GATSBY_USE_MIDDLEWARE_V2?.toLowerCase() === 'true'

  const { data, isLoading, isError, isFetching } = tokkoKey
    ? middlewarev2
      ? useGetDevelopmentMiddlewareQuery({ DEVELOPMENT_ID: id }, { skip })
      : useGetDevelopmentsTokkoQuery({ API_KEY: tokkoKey, DEVELOPMENT_ID: id }, { skip })
    : useGetDevelopmentsMediacoreQuery(
        {
          CLIENT_ID: process.env.GATSBY_CLIENTID,
          DEVELOPMENT_ID: id,
        },
        { skip },
      )

  return { data, isLoading, isError, isFetching }
}

export default useQueryDevelopment
